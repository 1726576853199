.timeline-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.timeline-line {
  width: 2px;
  background-color: lightgray;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 30px;
  /* To place it in the center */
  position: absolute;
}

.timeline-events {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}

.timeline-event {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  position: relative;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.3s, transform 0.3s;
  width: calc(100% - 60px);
  /* Taking up all space minus space for the line and the icon */
  justify-content: flex-start;
}

.timeline-icon {
  padding: 5px;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  z-index: 1;
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.timeline-card {
  transition: transform 0.3s ease;
  margin-left: 30px;
}

.timeline-card:hover {
  transform: scale(1.05);
}

.timeline-event.visible {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.3s;
}

.timeline-card-link, .timeline-card-link * {
  display: block;
  text-decoration: none;
  color: inherit;
}
