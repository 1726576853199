.App {
  font-family: sans-serif;
  text-align: left;
}

a {
  color: #007BFF; /* Bootstrap primary blue */
}

a:hover, a:focus {
  color: #0056b3; /* darker blue */
}

a:active {
  color: #003d6f; /* even darker blue */
}